import src from '../../../../assets/images/bobtailYearlings.png'

export default {
    src,
    year: 2023,
    email: `info@bobtailyearlings.com`,
    bandUrl: `https://bobtailyearlings.com`,
    projectUrl: `https://yearlingsbobtail.com`,
    onesheetUrl: `https://yearlingsbobtail.com/promo/onesheet`,
    band: `Bobtail Yearlings`,
    project: `Yearling's Bobtail`,
    description: `A double album and audio webcomic for the "*Ulysses* of rock albums."`,
    body: `
**How does this advance the art form?**

Ulysses of rock albums. Doublespeaker rhyme.

Audio webcomic.

Proof of concept.

**What else do you have to offer?**

Bobtail Method.

Bobtail Dominoes.

Amnesticism.

Next two albums ready to go.

Band committed to advancing the art form.

A million dollars.

**Who are you looking for?**

Partner.

Label.
    `,
}
